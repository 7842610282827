const sitemap = [
    {
        id: "Abilities",
        name: "Abilities",
        link: "Abilities"
    },
    {
        id: "Age",
        name: "Age",
        link: "Age"
    },
    {
        id: "All Pages",
        name: "All Pages",
        link: "All_Pages"
    },
    {
        id: "Aquatic Stage",
        name: "Aquatic Stage",
        link: "Aquatic_Stage"
    },
    {
        id: "Asteroids",
        name: "Asteroids",
        link: "Asteroids"
    },
    {
        id: "Biomes",
        name: "Biomes",
        link: "Biomes"
    },
    {
        id: "Black Holes",
        name: "Black Holes",
        link: "Black_Holes"
    },
    {
        id: "Blorb Clicker",
        name: "Blorb Clicker",
        link: "Blorb_Clicker"
    },
    {
        id: "Blorb Zone",
        name: "Blorb Zone",
        link: "Blorb_Zone"
    },
    {
        id: "Blorb",
        name: "Blorb",
        link: "Blorb"
    },
    {
        id: "Bob",
        name: "Bob",
        link: "Bob"
    },
    {
        id: "Cell Stage",
        name: "Cell Stage",
        link: "Cell_Stage"
    },
    {
        id: "Cheats",
        name: "Cheats",
        link: "Cheats"
    },
    {
        id: "Community Contests",
        name: "Community Contests",
        link: "Community_Contests"
    },
    {
        id: "Concept Art",
        name: "Concept Art",
        link: "Concept_Art"
    },
    {
        id: "Crabfish",
        name: "Crabfish",
        link: "Crabfish"
    },
    {
        id: "Creation Cards",
        name: "Creation Cards",
        link: "Creation_Cards"
    },
    {
        id: "Creature Stage",
        name: "Creature Stage",
        link: "Creature_Stage"
    },
    {
        id: "Croncho",
        name: "Croncho",
        link: "Croncho"
    },
    {
        id: "Day Night Cycle",
        name: "Day Night Cycle",
        link: "Day_Night_Cycle"
    },
    {
        id: "Devlogs",
        name: "Devlogs",
        link: "Elysian_Logs"
    },
    {
        id: "Editors",
        name: "Editors",
        link: "Editors"
    },
    {
        id: "Elysian Archive",
        name: "Elysian Archive",
        link: "Elysian_Archive"
    },
    {
        id: "Elysian Eclipse Wiki",
        name: "Elysian Eclipse Wiki",
        link: "Elysian_Eclipse_Wiki"
    },
    {
        id: "Elysian Logs",
        name: "Elysian Logs",
        link: "Elysian_Logs"
    },
    {
        id: "Elysian Mesh",
        name: "Elysian Mesh",
        link: "Elysian_Mesh"
    },
    {
        id: "EP",
        name: "EP",
        link: "Evolution_Points"
    },
    {
        id: "Evolution Points",
        name: "Evolution Points",
        link: "Evolution_Points"
    },
    {
        id: "Galaxies",
        name: "Galaxies",
        link: "Galaxies"
    },
    {
        id: "Gother",
        name: "Gother",
        link: "Gother"
    },
    {
        id: "Industrial Stage",
        name: "Industrial Stage",
        link: "Industrial_Stage"
    },
    {
        id: "Inventory",
        name: "Inventory",
        link: "Inventory"
    },
    {
        id: "Items",
        name: "Items",
        link: "Items"
    },
    {
        id: "Kibby",
        name: "Kibby",
        link: "Kibby"
    },
    {
        id: "Luca",
        name: "Luca",
        link: "Luca"
    },
    {
        id: "Map",
        name: "Map",
        link: "Map"
    },
    {
        id: "Medieval Stage",
        name: "Medieval Stage",
        link: "Medieval_Stage"
    },
    {
        id: "Microfossils",
        name: "Microfossils",
        link: "Items"
    },
    {
        id: "Nugget",
        name: "Nugget",
        link: "Nugget"
    },
    {
        id: "Nutrients",
        name: "Nutrients",
        link: "Items"
    },
    {
        id: "Parts",
        name: "Parts",
        link: "Parts"
    },
    {
        id: "Planets",
        name: "Planets",
        link: "Planets"
    },
    {
        id: "Premade Creations",
        name: "Premade Creations",
        link: "Premade_Creations"
    },
    {
        id: "Quaxel Engine",
        name: "Quaxel Engine",
        link: "Quaxel_Engine"
    },
    {
        id: "Quests",
        name: "Quests",
        link: "Quests"
    },
    {
        id: "Releases",
        name: "Releases",
        link: "Releases"
    },
    {
        id: "Stars",
        name: "Stars",
        link: "Stars"
    },
    {
        id: "Stats",
        name: "Stats",
        link: "Stats"
    },
    {
        id: "Status Effects",
        name: "Status Effects",
        link: "Status_Effects"
    },
    {
        id: "Seven Ducks Studios",
        name: "Seven Ducks Studios",
        link: "Seven_Ducks_Studios"
    },
    {
        id: "Soundtrack",
        name: "Soundtrack",
        link: "Soundtrack"
    },
    {
        id: "Space Stage",
        name: "Space Stage",
        link: "Space_Stage"
    },
    {
        id: "Splash Texts",
        name: "Splash Texts",
        link: "Splash_Texts"
    },
    {
        id: "Swoops",
        name: "Swoops",
        link: "Swoops"
    },
    {
        id: "Tribal Stage",
        name: "Tribal Stage",
        link: "Tribal_Stage"
    },
    {
        id: "V 0.1 Patchnotes",
        name: "V 0.1 Patchnotes",
        link: "V_0.1_Patchnotes"
    },
    {
        id: "V 0.2 Patchnotes",
        name: "V 0.2 Patchnotes",
        link: "V_0.2_Patchnotes"
    },
    {
        id: "V 0.3 Patchnotes",
        name: "V 0.3 Patchnotes",
        link: "V_0.3_Patchnotes"
    },
    {
        id: "V 0.4 Patchnotes",
        name: "V 0.4 Patchnotes",
        link: "V_0.4_Patchnotes"
    },
    {
        id: "V 0.5 Patchnotes",
        name: "V 0.5 Patchnotes",
        link: "V_0.5_Patchnotes"
    },
    {
        id: "Wauzmons",
        name: "Wauzmons",
        link: "Wauzmons"
    },
    {
        id: "Weather",
        name: "Weather",
        link: "Weather"
    },
    {
        id: "Wooblor",
        name: "Wooblor",
        link: "Wooblor"
    }
];

export default sitemap;